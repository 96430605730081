
import {Component, Vue} from "vue-property-decorator";
import {
  systemRoleCreateApi,
  systemRoleDetailApi,
  systemRoleModifyApi,
  systemRoleQueryApi,
  systemRoleRemoveApi
} from "@/apis/systemManage/role";
import {ISystemRole, ISystemRoleQuery} from "@/apis/systemManage/role/types";
import {systemMenuQueryApi} from "@/apis/systemManage/menu";
import { deepCopy } from "@/utils/common";

/**
 * @name: 系统管理-角色管理
 * @author: lili
 * @date: 2023-07-19 10:08
 * @description： 系统管理-角色管理
 * @update: 2023-07-19 10:08
 */
@Component({})
export default class RolePage extends Vue {
  // 查询参数
  queryParams: ISystemRoleQuery = {
    page: 1,
    limit: 10,
    roleName: ''
  }

  // 表格加载状态
  loading = false

  // 角色列表
  roleList: ISystemRole[] = []
  // 数据总数
  total = 0


  menuExpand = false
  menuNodeAll = false

  // 新增 / 编辑表单
  form: Partial<ISystemRole> = {
    id: '',
    roleName: '',
    status: 2,
    menuIdList: [],

    menuCheckStrictly: true
  }

  // 弹窗
  open = false

  // 弹窗标题
  title = ''

  rules: any = {
    roleName: [
      { required: true, message: "角色名称不能为空", trigger: "blur" }
    ],
  }

  // 菜单列表
  menuOptions: any = []

  defaultProps: any = {
    children: "children",
    label: "title"
  }

  /**
   * 搜索
   */
  handleQuery() {
    this.queryParams.page = 1;
    this.getList();
  }

  /**
   * 重置
   */
  resetQuery() {
    // @ts-ignore
    this.$refs.queryForm.resetFields()
    this.handleQuery();
  }

  /**
   * 重置表单
   */
  reset() {
    if (this.$refs.menu != undefined) {
      // @ts-ignore
      this.$refs.menu.setCheckedKeys([]);
    }
    this.menuExpand = false
    this.menuNodeAll = false
    this.form = {
      id: '',
      roleName: '',
      status: 2,
      menuIdList: [],

      menuCheckStrictly: true
    };
    if (this.$refs.form) {
      // @ts-ignore
      this.$refs.form.clearValidate();
      // @ts-ignore
      this.$refs.form.resetFields();
    }
  }

  /**
   * 获取树形菜单
   */
  getMenuTreeselect() {
    return new Promise(resolve => {
      systemMenuQueryApi({isHide: false}).then(e => {
        this.menuOptions = e;
        resolve(e)
      })
    })
  }

  /**
   * 新增
   */
  handleAdd() {
    this.reset();
    this.getMenuTreeselect();
    this.open = true;
    this.title = "添加角色";
  }

  /**
   * 切换状态
   * @param id 角色id
   * @param status 角色状态
   */
  handleStatusChange(id: string, status: number) {
    systemRoleModifyApi({id, status} as ISystemRole).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      })
      this.getList()
    })
  }

  /**
   * 打开编辑
   * @param row
   */
  async handleUpdate(row: ISystemRole) {
    this.reset();
    const roleId = row.id
    await this.getMenuTreeselect()
    systemRoleDetailApi(roleId).then(e => {
      if (e) {
        this.form = e;
        this.open = true;
        this.$nextTick(() => {
          let checkedKeys = e.menuIdList
          checkedKeys.forEach((v: string) => {
            this.$nextTick(()=>{
              // @ts-ignore
              this.$refs.menu.setChecked(v, true ,false);
            })
          })
        });
        this.title = "修改角色";
      }
    })
  }

  /**
   * 删除
   * @param row
   */
  handleDelete(row: ISystemRole) {
    this.$confirm('确认删除？', '提示', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      systemRoleRemoveApi(row.id).then(e => {
        if (e) {
          this.$message.success("删除成功!")
          this.getList()
        }
      })
    }).catch((action ) => {
    });
  }

  /**
   * 树权限（展开/折叠）
   * @param value
   */
  handleCheckedTreeExpand(value: any) {
    let treeList = this.menuOptions;
    for (let i = 0; i < treeList.length; i++) {
      // @ts-ignore
      this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value;
    }
  }

  /**
   * 树权限（全选/全不选）
   * @param value
   */
  handleCheckedTreeNodeAll(value: any) {
    // @ts-ignore
    this.$refs.menu.setCheckedNodes(value ? this.menuOptions: []);
  }

  /**
   * 树权限（父子联动）
   * @param value
   */
  handleCheckedTreeConnect(value: any) {
    this.form.menuCheckStrictly = value ? true: false;
  }

  getMenuAllCheckedKeys() {
    // 目前被选中的菜单节点
    // @ts-ignore
    let checkedKeys = this.$refs.menu.getCheckedKeys();
    // 半选中的菜单节点
    // @ts-ignore
    let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
    checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
    return checkedKeys;
  }

  submitForm() {
    // @ts-ignore
    this.$refs["form"].validate(valid => {
      if (valid) {
        const ids: string[] = this.getMenuAllCheckedKeys();
        if (!ids || !ids.length) {
          this.$message.error("请选择菜单")
          return
        }
        if (this.form.id) {
          this.form.menuIdList = ids
          systemRoleModifyApi(this.form as ISystemRole).then(e => {
            this.$message.success("修改成功")
            this.open = false;
            this.getList();
          })
        } else {
          this.form.menuIdList = ids
          systemRoleCreateApi(this.form as ISystemRole).then(e => {
            this.$message.success("新增成功")
            this.open = false;
            this.getList();
          })
        }
      }
    });
  }

  cancel() {
    this.open = false;
    this.reset();
  }

  /**
   * 查询
   */
  getList() {
    this.loading = true

    systemRoleQueryApi(this.queryParams).then(e => {
      this.roleList = e.list
      this.total = e.total
      this.loading = false
    })
  }

  created() {
    this.getList();
  }
}
